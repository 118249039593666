body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  touch-action: none;
}

.game-header {
  padding: 30px;
  background-color: tomato;
  color: white;
  box-shadow: 10px 10px black;
}

.header-title {
  margin: auto;
  text-align: center;
}

.main-content {
  display: flex;
  flex-direction: column;
}

.board-flex {
  display: flex;
}

.timer-wrapper {
  margin: 30px;
}

.timer {
  background-color: black;
  color: white;
  user-select: none;
  padding: 20px;
  margin: auto;
  max-width: 500px;
  text-align: center;
  border: 10px solid black;
  font-size: 2em;
}
.inactive {
  color: grey;
}
.won {
  color: lightgreen;
}

.game-board {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  padding: 10px;
  margin: auto;
  border: 10px solid black;
  box-shadow: 10px 10px grey;
}

.tile {
  width: 100%;
  background-color: lightgreen;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px grey;
  aspect-ratio: 1;
}

.tile-empty {
  display: none;
}

.tile-label {
  user-select: none;
}

.victory {
  grid-row: 2 / span 2;
  grid-column: 2 / span 2;
  text-align: center;
}

.victory-button {
  border: 0;
  background-color: tomato;
  color: white;
  padding: 20px 40px;
  font-size: 1.3em;
  cursor: pointer;
}
